<template>
  <v-container fluid>
    <PageHeader showBackButton :isLoading="$store.getters.isLoading">
      <template #rightSlot>
        <v-btn
          v-if="id !== null"
          depressed
          color="primary accent-4"
          class="white--text"
          :to="{ name: 'CreateRedeem', params: { giftId: id } }"
          >發送禮物</v-btn
        >
      </template>
    </PageHeader>
    <v-form ref="dataForm" @submit.prevent="save">
      <v-card class="mb-8">
        <v-card-text>
          <v-card-title class="page-title">禮品資料</v-card-title>
          <v-row class="ma-0">
            <v-col cols="12">
              <form-button-upload
                :fileValue.sync="formData.gift_image"
                label="禮品圖片"
                ref="uploadImage"
                inputRef="imgFile"
              />
            </v-col>
            <v-col cols="12" md="6">
              <form-input label="禮品名稱" :fieldValue.sync="formData.name" />
            </v-col>
            <v-col cols="12" md="6">
              <form-select label="狀態" :fieldValue.sync="formData.status" :options="$statusOptions" required />
            </v-col>
            <v-col cols="12" md="6">
              <form-input
                label="所需積分"
                :fieldValue.sync="formData.point_required"
                required
                type="number"
                isIntegerOnly
              />
            </v-col>
            <v-col cols="12" md="6">
              <form-input label="庫存" :fieldValue.sync="formData.inventory" required type="number" isIntegerOnly />
            </v-col>
            <v-col cols="12">
              <quill-editor :html.sync="formData.description" editorID="editor" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-btn depressed color="primary accent-4" class="white--text" type="submit" :loading="$store.getters.isLoading"
        >儲存</v-btn
      >
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import FormInput from '@/components/form/FormInput.vue'
import FormSelect from '@/components/form/FormSelect.vue'
import FormButtonUpload from '@/components/form/FormButtonUpload.vue'
import QuillEditor from '@/components/QuillEditor.vue'

export default {
  name: 'GiftDetail',
  components: {
    FormInput,
    FormSelect,
    FormButtonUpload,
    QuillEditor,
  },
  computed: {
    id() {
      const id = parseInt(this.$route.params.id)
      if (!isNaN(id)) {
        return id
      }

      return null
    },
  },
  data: () => ({
    formData: {
      gift_image: '',
      name: '',
      status: 'active',
      point_required: '',
      inventory: '',
      description: '',
    },
  }),

  methods: {
    ...mapActions(['setAlert']),
    async getDetail() {
      try {
        const user = this.getCurrentUserData()
        const data = await this.$Fetcher.GetGiftById(this.id, user.id, user.token)
        for (const key in this.formData) {
          if (this.$validate.DataValid(data[key])) {
            this.$set(this.formData, key, data[key])
          }
        }
      } catch (err) {
        this.$common.error(err)
        if (err) {
          this.setAlert({
            type: 'error',
            message: err,
            redirect: 'GiftList',
          })
        } else {
          this.setAlert({
            message: 'noData',
            redirect: 'GiftList',
          })
        }
      } finally {
        this.$store.dispatch('setInitLoading', false)
      }
    },

    async save() {
      if (this.$store.getters.isLoading) {
        this.$store.dispatch('toggleSnack', {
          message: 'processing',
        })
        return
      }

      const valid = await this.$refs['dataForm'].validate()
      if (!valid) {
        this.$store.dispatch('toggleSnack', {
          message: 'required',
        })
        return
      }

      const payload = this.$common.duplicateData(this.formData)
      payload['point_required'] = parseFloat(payload['point_required'])
      payload['inventory'] = parseFloat(payload['inventory'])
      const user = this.getCurrentUserData()

      this.$store.dispatch('setLoading', true)
      if (this.$validate.DataValid(this.id)) {
        // edit
        try {
          await this.$Fetcher.UpdateGift(this.id, payload, user.id, user.token)
          this.$store.dispatch('setDataIsUpdated', false)
          this.$store.dispatch('toggleSnack', {
            type: 'success',
            message: '儲存成功',
            refresh: true,
          })
        } catch (err) {
          this.setAlert({
            title: '儲存失敗',
            message: err,
            type: 'error',
          })
        } finally {
          this.$store.dispatch('setLoading', false)
        }
      } else {
        try {
          await this.$Fetcher.NewGift(payload, user.id, user.token)
          this.$store.dispatch('setDataIsUpdated', false)
          this.$store.dispatch('toggleSnack', {
            type: 'success',
            message: '新增成功',
            redirect: 'GiftList',
          })
        } catch (err) {
          this.setAlert({
            title: '新增失敗',
            message: err,
            type: 'error',
          })
        } finally {
          this.$store.dispatch('setLoading', false)
        }
      }
    },
  },
  async created() {
    this.$store.dispatch('setInitLoading', true)
    if (this.$validate.DataValid(this.id)) {
      this.getDetail()
    } else {
      this.$store.dispatch('setInitLoading', false)
    }
  },

  // ------ navigation guard ------
  beforeRouteLeave(to, from, next) {
    if (this.$store.getters.isLoading) {
      this.$store.dispatch('toggleSnack', {
        show: true,
        message: 'processing',
        type: 'error',
        refresh: false,
        redirect: '',
      })
      next(false)
    } else {
      next()
    }
  },
}
</script>
